import React, { useEffect, useState } from 'react';
import { Menu, Select, SelectOption } from '@recargas-dominicanas/core/components';
import { MenuOption } from '../menu-option/menu-option.component';
import { style } from './date-picker.module.css';
var monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];
export function DatePicker(props) {
    var open = props.open, anchor = props.anchor, onClose = props.onClose, onChange = props.onChange;
    var date = useState(new Date())[0];
    var _a = useState(), month = _a[0], setMonth = _a[1];
    var _b = useState([]), months = _b[0], setMonths = _b[1];
    var _c = useState(), year = _c[0], setYear = _c[1];
    var _d = useState([]), years = _d[0], setYears = _d[1];
    useEffect(function () {
        var date = new Date();
        var startDate = new Date(date.getFullYear() - 1, date.getMonth());
        var months = [];
        for (var i = 12; i >= 1; i--) {
            var date_1 = new Date(startDate.getFullYear(), startDate.getMonth() + i);
            months.push({
                date: date_1,
                name: monthNames[date_1.getMonth()],
                year: date_1.getFullYear()
            });
        }
        setMonths(months);
    }, []);
    useEffect(function () {
        var currentYear = new Date().getFullYear();
        var years = [];
        for (var i = 2022; i <= currentYear; i++) {
            years.unshift(i);
        }
        setYears(years);
    }, []);
    function handleCurrentMonth() {
        var startDate = new Date(date.getFullYear(), date.getMonth());
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1);
        setMonth(undefined);
        setYear(undefined);
        onChange(startDate, endDate, 'Mes actual', 'month');
        onClose();
    }
    function handleLastMonth() {
        var startDate = new Date(date.getFullYear(), date.getMonth() - 1);
        var endDate = new Date(date.getFullYear(), date.getMonth());
        setMonth(undefined);
        setYear(undefined);
        onChange(startDate, endDate, 'Mes anterior', 'month');
        onClose();
    }
    function handleMonthSelectChange(month) {
        if (month) {
            var startDate = new Date(month.date.getFullYear(), month.date.getMonth());
            var endDate = new Date(month.date.getFullYear(), month.date.getMonth() + 1);
            var label = "".concat(month.name, " ").concat(month.year);
            setMonth(month);
            setYear(undefined);
            onChange(startDate, endDate, label, 'month');
            onClose();
        }
    }
    function handleCurrentYear() {
        var startDate = new Date(date.getFullYear(), 0);
        var endDate = new Date(date.getFullYear() + 1, 0);
        setMonth(undefined);
        setYear(undefined);
        onChange(startDate, endDate, 'Año actual', 'year');
        onClose();
    }
    function handleLastYear() {
        var startDate = new Date(date.getFullYear() - 1, date.getMonth());
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1);
        setMonth(undefined);
        setYear(undefined);
        onChange(startDate, endDate, 'Ultimo año', 'year');
        onClose();
    }
    function handleYearSelectChange(year) {
        if (year) {
            var startDate = new Date(year, 0);
            var endDate = new Date(year + 1, 0);
            setMonth(undefined);
            setYear(year);
            onChange(startDate, endDate, "A\u00F1o ".concat(year.toString()), 'year');
            onClose();
        }
    }
    return (React.createElement(Menu, { style: style.menu, open: open, anchor: anchor, top: 8, disabledClick: true, onClose: function () { return onClose(); } },
        React.createElement("div", { className: style.title }, "Mensual"),
        React.createElement(MenuOption, { style: style.menuOption, text: 'Mes actual', onClick: handleCurrentMonth }),
        React.createElement(MenuOption, { style: style.menuOption, text: 'Mes anterior', onClick: handleLastMonth }),
        React.createElement(Select, { style: style.select, value: month, valueTitle: month ? "".concat(month.name, " ").concat(month.year) : undefined, placeholder: 'Seleccionar mes', onChange: handleMonthSelectChange }, months.map(function (month) {
            return React.createElement(SelectOption, { key: month.name, style: style.selectOption, value: month, label: "".concat(month.name, " ").concat(month.year), showClearIcon: false },
                React.createElement("span", null, month.name),
                React.createElement("span", null, month.year));
        })),
        React.createElement("div", { className: style.title }, "Anual"),
        React.createElement(MenuOption, { style: style.menuOption, text: 'Ultimo a\u00F1o', onClick: handleLastYear }),
        React.createElement(MenuOption, { style: style.menuOption, text: 'A\u00F1o actual', onClick: handleCurrentYear }),
        React.createElement(Select, { style: style.select, value: year, valueTitle: year ? "A\u00F1o ".concat(year) : undefined, placeholder: 'Seleccionar a\u00F1o', onChange: handleYearSelectChange }, years.map(function (year) {
            return React.createElement(SelectOption, { key: year, style: style.selectOption, value: year, label: "A\u00F1o ".concat(year), showClearIcon: false }, year);
        }))));
}
