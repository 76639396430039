import clsx from 'clsx';
import React from 'react';
import { mergeStyle } from './option-button.module.css';
import { Icon } from '@recargas-dominicanas/core/components';
export function OptionButton(props) {
    var className = props.className, icon = props.icon, title = props.title, rightIcon = props.rightIcon, optionRef = props.optionRef, onClick = props.onClick;
    var style = mergeStyle(className);
    return (React.createElement("div", { ref: optionRef, className: style.container, onClick: onClick },
        React.createElement(Icon, { className: style.icon, icon: icon }),
        React.createElement("span", { className: clsx(style.title, !rightIcon && style.margin12px) }, title),
        rightIcon &&
            React.createElement(Icon, { className: style.iconRight, icon: rightIcon })));
}
