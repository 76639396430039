var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import logo from '../../images/recargas-dominicanas.svg';
import errorImage from '../../images/server-error.png';
import { Switch, Route } from 'react-router-dom';
import { useAsyncEffect } from '@recargas-dominicanas/core/utils';
import { useOpenPopup } from '@recargas-dominicanas/core/hooks';
import { AdminStore } from '../../store/adminStore';
import { ProductStore } from '../../store/productStore';
import { BusinessStore } from '../../store/businessStore';
import { BankStore } from '../../store/bankStore';
import { BankAccountStore } from '../../store/bankAccountStore';
import { ProviderStore } from '../../store/providerStore';
import { AppReleaseStore } from '../../store/appReleaseStore';
import { FinanceStore } from '../../store/financeStore';
import { Home } from '../home/home.component';
import { UserProfile } from '../../components/user-profile/user-profile.component';
import { CreatedCustomer } from '../created-customer/created-customer.component';
import { Customer } from '../customer/customer.component';
import { Products } from '../products/products.component';
import { Providers } from '../providers/providers.component';
import { Provider } from '../provider/provider.component';
import { BankAccounts } from '../bank-accounts/bank-accounts.components';
import { BankAccount } from '../bank-account/bank-account.component';
import { Banks } from '../banks/banks.component';
import { Bank } from '../bank/bank.component';
import { AppReleases } from '../app-releases/app-releases.component';
import { Finances } from '../finances/finances.component';
import { style } from './main.module.css';
import { ErrorPage, Header, Navbar, NavbarOption } from '@recargas-dominicanas/core/components';
export function Main() {
    var _this = this;
    var admin = AdminStore.get();
    var openProfile = useOpenPopup();
    var _a = useState(true), isLoading = _a[0], setLoading = _a[1];
    var _b = useState(false), loadFailed = _b[0], setLoadFailed = _b[1];
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Promise.all([
                            AdminStore.fetch(),
                            BankStore.fetchAll(),
                            ProductStore.fetchAll(),
                            BusinessStore.fetchAll(),
                            ProviderStore.fetchAll(),
                            BankAccountStore.fetchAll(),
                            AppReleaseStore.fetchAll(),
                            FinanceStore.fetch()
                        ])];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    setLoadFailed(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); });
    if (isLoading) {
        return null;
    }
    if (loadFailed) {
        return React.createElement(ErrorPage, { image: errorImage });
    }
    return (React.createElement("div", null,
        React.createElement(Header, { logo: logo, title: 'Admin Recargas Dominicanas', name: "".concat(admin.name, ", Administrador"), onOpenProfile: openProfile.handleOpen }),
        React.createElement("div", { className: style.body },
            React.createElement(Navbar, { style: style.navBar },
                React.createElement(NavbarOption, { align: 'top', icon: 'groups', route: '/' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'account_balance', route: '/bank-accounts' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'inventory_2', route: '/products' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'widgets', route: '/providers' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'donut_large', route: '/finances' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'rocket_launch', route: '/app-releases' })),
            React.createElement("div", { className: style.content },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: '/', component: Home }),
                    React.createElement(Route, { exact: true, path: '/customer/:id', component: Customer }),
                    React.createElement(Route, { exact: true, path: '/created-customer', component: CreatedCustomer }),
                    React.createElement(Route, { exact: true, path: '/products', component: Products }),
                    React.createElement(Route, { exact: true, path: '/bank-accounts', component: BankAccounts }),
                    React.createElement(Route, { exact: true, path: '/bank-account/:id', component: BankAccount }),
                    React.createElement(Route, { exact: true, path: '/banks', component: Banks }),
                    React.createElement(Route, { exact: true, path: '/bank/:id', component: Bank }),
                    React.createElement(Route, { exact: true, path: '/providers', component: Providers }),
                    React.createElement(Route, { exact: true, path: '/provider/:id', component: Provider }),
                    React.createElement(Route, { exact: true, path: '/finances', component: Finances }),
                    React.createElement(Route, { exact: true, path: '/app-releases', component: AppReleases })))),
        React.createElement(UserProfile, { open: openProfile.open, admin: admin, onClose: openProfile.handleClose })));
}
