import React from 'react';
import { mergeStyle } from './modal.module.css';
import { Modal as Base, ModalToolbar, Error, OutlineButton, FillButton, ModalActions, ModalContent } from '@recargas-dominicanas/core/components';
export function Modal(props) {
    var open = props.open, icon = props.icon, title = props.title, children = props.children, error = props.error, customStyle = props.style, _a = props.primaryButton, primaryButton = _a === void 0 ? 'Aceptar' : _a, _b = props.secondaryButton, secondaryButton = _b === void 0 ? 'Cancelar' : _b, _c = props.disabledPrimaryButton, disabledPrimaryButton = _c === void 0 ? false : _c, _d = props.disabledSecondaryButton, disabledSecondaryButton = _d === void 0 ? false : _d, onPrimaryClick = props.onPrimaryClick, onSecondaryClick = props.onSecondaryClick, onClose = props.onClose;
    var style = mergeStyle(customStyle);
    style.modal = { card: "".concat(style.modal.card, " ").concat(customStyle === null || customStyle === void 0 ? void 0 : customStyle.card) };
    return (React.createElement(Base, { style: style.modal, open: open, onClose: onClose },
        React.createElement(ModalToolbar, { icon: icon, title: title, style: style.modalToolbar, onClose: onClose }),
        React.createElement(ModalContent, { className: style.content }, children),
        React.createElement(Error, { style: style.error, message: error }),
        React.createElement(ModalActions, { style: style.modalActions },
            onSecondaryClick &&
                React.createElement(OutlineButton, { text: secondaryButton, disabled: disabledSecondaryButton, onClick: onSecondaryClick }),
            onPrimaryClick &&
                React.createElement(FillButton, { text: primaryButton, disabled: disabledPrimaryButton, onClick: onPrimaryClick }))));
}
