import React, { useEffect } from 'react';
import { Cell, Pagination, Table, TableHeader, TableRow, Text } from '@recargas-dominicanas/core/components';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { BankAccountStore } from '../../../store/bankAccountStore';
import { style } from './deposits.module.css';
export function Deposits(props) {
    var depositsPage = BankAccountStore.getDeposits(props.id);
    useEffect(function () {
        BankAccountStore.fetchDeposits(props.id, 1, 100);
    }, [props.id]);
    function handleFetch(page, size) {
        BankAccountStore.fetchDeposits(props.id, page, size);
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.tableContainer },
            React.createElement(Table, { style: style.table },
                React.createElement(TableHeader, { style: style.tableHeader },
                    React.createElement(Text, { text: 'Balance' }),
                    React.createElement(Text, { text: 'Fecha' }),
                    React.createElement(Text, { text: 'Negocio' }),
                    React.createElement(Text, { text: 'Descripci\u00F3n' })),
                React.createElement("tbody", null, depositsPage === null || depositsPage === void 0 ? void 0 : depositsPage.data.map(function (deposit) {
                    var _a;
                    return React.createElement(TableRow, { key: deposit.id, style: style.tableRow },
                        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(deposit.balance.amount) }),
                        React.createElement(Cell, { text: formatDate(deposit.date) }),
                        React.createElement(Cell, { text: ((_a = deposit.business) === null || _a === void 0 ? void 0 : _a.name) || '' }),
                        React.createElement(Cell, { className: style.description, text: deposit.reference }));
                })))),
        depositsPage &&
            React.createElement(Pagination, { title: 'Depositos', index: depositsPage.index, pages: depositsPage.pages, count: depositsPage.count, size: depositsPage.size, onChange: handleFetch })));
}
