import React from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { useHistory } from 'react-router';
import { TableRow } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
export function BusinessItem(props) {
    var _a;
    var business = props.business;
    var history = useHistory();
    function handleClick() {
        history.push("/customer/".concat(business.id));
    }
    return (React.createElement(TableRow, { onClick: handleClick },
        React.createElement(Cell, { weight: 'medium', text: business.id }),
        React.createElement(Cell, { weight: 'medium', text: business.name }),
        React.createElement(Cell, { text: (_a = business.customer) === null || _a === void 0 ? void 0 : _a.name }),
        React.createElement(Cell, { text: business.city }),
        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(business.balance) }),
        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(business.sales) }),
        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(business.profit) })));
}
