import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AdminStore } from './adminStore';
import { BankAccountStore } from './bankAccountStore';
import { BankStore } from './bankStore';
import { BusinessStore } from './businessStore';
import { ProductStore } from './productStore';
import { ProviderStore } from './providerStore';
import { AppReleaseStore } from './appReleaseStore';
import { FinanceStore } from './financeStore';
export var store = configureStore({
    reducer: {
        admin: AdminStore.reducer(),
        businessState: BusinessStore.reducer(),
        productsState: ProductStore.reducer(),
        bankState: BankStore.reducer(),
        bankAccounts: BankAccountStore.reducer(),
        providersState: ProviderStore.reducer(),
        appReleaseState: AppReleaseStore.reducer(),
        finance: FinanceStore.reducer()
    }
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
