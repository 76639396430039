// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposits-container-dpoLe {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.deposits-tableContainer-zXMA0 {
  height: 100%;
  padding-left: 10px;
  overflow: hidden;
}

.deposits-table_container-tEQzq {
  padding: 0px 10px;
}

.deposits-tableHeader_cellContent-_IxVk {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.deposits-description-dp1eS {
  min-width: 380px;
  padding: 8px 26px;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/deposits/deposits.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.tableContainer {\n  height: 100%;\n  padding-left: 10px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.description {\n  min-width: 380px;\n  padding: 8px 26px;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposits-container-dpoLe`,
	"tableContainer": `deposits-tableContainer-zXMA0`,
	"table_container": `deposits-table_container-tEQzq`,
	"tableHeader_cellContent": `deposits-tableHeader_cellContent-_IxVk`,
	"description": `deposits-description-dp1eS`
};
export default ___CSS_LOADER_EXPORT___;
